import React from "react"
import PropTypes from "prop-types"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import {ErrorMessage } from "../styled"

let selectedDate = null

//https://reactdatepicker.com/#example-custom-header
export const DateInput = ({
  field,
  form: {touched, errors, setFieldValue},
  ...props
}) => {
  return (
    <>
      <DatePicker
        selected={selectedDate}
        onSelect={(val) => { selectedDate = val; setFieldValue("birthdate", val) } }
        showYearDropdown
        showMonthDropdown
        placeholderText={props.placeholder}
        popperPlacement="top-end"
        {...field} {...props} className="form-control"/>
      {touched[field.name] && errors[field.name] && <ErrorMessage>{errors[field.name]}</ErrorMessage>}
    </>
  )
}

DateInput.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  placeholder: PropTypes.string.isRequired
}
